import React                                from "react";
import { createRoot }                       from "react-dom/client";
import * as ServiceWorker                   from "serviceWorker";

// Utils
import Store                                from "Utils/Store";

// Components
import Main                                 from "Core/Main";

// Styles
import "Styles/Main.css";
import "Styles/Properties.css";
import "Styles/Icons.css";



// Render the App
const root = createRoot(document.getElementById("root"));
root.render(
    <Store.Provider>
        <Main />
    </Store.Provider>
);

ServiceWorker.register();
