import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Slider               from "Components/Utils/Slider";



// Styles
const Container = Styled.section`
    padding-bottom: 32px;
`;



/**
 * The Banners
 * @returns {React.ReactElement}
 */
function Banners() {
    const { banners, options } = Store.useState();

    const showPage = Store.useShowPage();


    // Handles the Click
    const handleClick = (elem) => {
        if (elem.url) {
            window.open(elem.url, "_blank");
        } else {
            showPage(elem.spaceID);
        }
    };


    // Do the Render
    if (!banners.length) {
        return <React.Fragment />;
    }
    return <Container>
        <Slider
            data={banners}
            onClick={handleClick}
            autoSlide={Utils.isActive(options.autoSlideBanners)}
            withArrows
            withDots
            alwaysDots
        />
    </Container>;
}

export default Banners;
