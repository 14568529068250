import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";

// Components
import Dialog               from "Components/Utils/Dialog";
import Button               from "Components/Utils/Button";



// Styles
const Container = Styled(Dialog)`
    position: absolute;
    left: calc((100vw - 800px) / 2);
    bottom: var(--main-padding);
    width: 400px;
    min-height: 50px;

    @media (max-width: 800px) {
        left: var(--main-padding);
    }
`;

const Language = Styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const Select = Styled.select`
    flex-grow: 2;
    margin: 0;
    padding: 8px 12px;
    border: var(--border);
    border-radius: var(--border-radius-small);
    background-color: white;
    font-size: 14px;
    color: #687992;

    &:focus, &:hover {
        border-color: black;
        outline: none;
    }
`;



/**
 * The Settings
 * @returns {React.ReactElement}
 */
function Settings() {
    const { language, languages } = Store.useState();

    const showSettings = Store.useSettings();
    const setLanguage  = Store.useLanguage();
    const getText      = Store.useText();


    // The Current State
    const [ newLanguage, setNewLanguage ] = React.useState(language);


    // Handles the Change
    const handleChange = (e) => {
        setNewLanguage(e.target.value);
    };

    // Handles the Save
    const handleSave = () => {
        if (language !== newLanguage) {
            setLanguage(newLanguage);
        }
        handleClose();
    };

    // Handles the Close
    const handleClose = () => {
        showSettings(false);
    };



    // Do the Render
    return <Container title={getText("settingsTitle")} onClose={handleClose}>
        <Language>
            <label htmlFor="select">
                {getText("settingsLanguage")}
            </label>
            <Select
                id="select"
                value={newLanguage}
                onChange={handleChange}
            >
                {languages.map(({ key, value }) => <option key={key} value={key}>
                    {value}
                </option>)}
            </Select>
        </Language>
        <Button
            onClick={handleSave}
            message={getText("settingsButton")}
            isPrimary
        />
    </Container>;
}

export default Settings;
