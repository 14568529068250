import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Icon                 from "Components/Utils/Icon";
import Button               from "Components/Utils/Button";



// Styles
const Container = Styled.header.attrs(({ leftAlign, showSearch }) => ({ leftAlign, showSearch }))`
    flex-shrink: 0;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    min-height: 70px;
    padding: 24px 24px 0 24px;
    margin-bottom: 40px;
    font-size: 28px;
    font-weight: 400;
    color: var(--store-header-text);
    background-color: var(--store-header-bg);
    z-index: 3;

    ${(props) => !props.showSearch && "padding-bottom: 24px;"}
`;

const Content = Styled.div.attrs(({ leftAlign, rightSearch }) => ({ leftAlign, rightSearch }))`
    display: flex;
    width: 100%;
    max-width: var(--main-width);
    margin: 0 auto;

    ${(props) => props.leftAlign ? `
        align-items: flex-start;
        text-align: left;
    ` : `
        align-items: center;
        text-align: center;
    `}

    ${(props) => props.rightSearch ? `
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 24px;
    ` : `
        flex-direction: column;
        justify-content: center;
        gap: 20px;
    `}

    @media (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        padding-bottom: 0;
    }
`;

const Image = Styled.img.attrs(({ height }) => ({ height }))`
    max-height: ${((props) => props.height || 0)}px;
    max-width: 100%;
    object-fit: contain;
`;

const Search = Styled.div.attrs(({ rightSearch }) => ({ rightSearch }))`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: calc(100vw - 24px);
    min-height: 44px;
    padding: 6px 12px 6px 16px;
    font-size: 20px;
    background-color: var(--store-search-bg);
    color: var(--store-search-color);
    border-radius: var(--border-radius);

    ${(props) => props.rightSearch ? `
        width: 240px;
    ` : `
        width: 440px;
        margin-bottom: -20px;
    `}

    @media (max-width: 600px) {
        margin-bottom: -20px;
        width: calc(100vw - 24px);
    }
`;

const Input = Styled.input`
    flex-grow: 2;
    appearance: none;
    margin: 0;
    border: none;
    color: white;
    background: transparent;
    font-family: var(--store-font);

    &:focus {
        outline: none;
    }
    &::placeholder {
        color: var(--store-search-color);
    }
`;



/**
 * The Header
 * @returns {React.ReactElement}
 */
function Header() {
    const { currentCategory, currentSearch, name, options } = Store.useState();

    const showPage        = Store.useShowPage();
    const setHeaderHeight = Store.useHeaderHeight();
    const getText         = Store.useText();


    // The References
    const containerRef = React.useRef(null);


    // Variables
    const headerText  = getText("headerText");
    const showName    = !headerText && !options.logoFile;
    const leftAlign   = Utils.isActive(options.headerLeftAlign);
    const showSearch  = Utils.isActive(options.showSearch);
    const rightSearch = showSearch && Utils.isActive(options.rightSearch);


    // Sets the Header Height
    React.useEffect(() => {
        if (containerRef.current) {
            const height = containerRef.current.getBoundingClientRect().height;
            setHeaderHeight(height);
        }
    }, []);

    // Handles the Message
    const handleInput = (e) => {
        if (e.target.value.length > 0) {
            showPage(currentCategory, e.target.value);
        } else {
            handleClear();
        }
    };

    // Handles the Submit
    const handleClear = () => {
        showPage(currentCategory);
    };


    // Do the Render
    return <Container ref={containerRef} showSearch={showSearch}>
        <Content leftAlign={leftAlign} rightSearch={rightSearch}>
            {!!options.logoFile && <Image
                src={options.logoFileUrl}
                height={options.logoHeight}
            />}
            {!!headerText && headerText}
            {showName && name}

            {showSearch && <Search rightSearch={rightSearch}>
                <Icon icon="search" />
                <Input
                    placeholder={getText("searchPlaceholder")}
                    value={currentSearch}
                    onInput={handleInput}
                />
                <Button
                    isHidden={!currentSearch}
                    icon="close"
                    onClick={handleClear}
                    isPrimary
                />
            </Search>}
        </Content>
    </Container>;
}

export default Header;
