import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";

// Components
import Icon                 from "Components/Utils/Icon";



// Styles
const Container = Styled.footer`
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 var(--main-padding);

    text-align: center;
    font-size: 12px;
    margin-top: 40px;
    margin-bottom: 40px;

    @media (max-width: 500px) {
        text-align: left;
        padding-left: calc(var(--main-padding) + 24px);
    }
`;

const Link = Styled.a`
    text-decoration: none;
    color: #91989e;
`;

const ConfigIcon = Styled(Icon)`
    position: absolute;
    top: 50%;
    left: var(--main-padding);
    transform: translateY(-50%);
    font-size: 18px;
    cursor: pointer;
`;



/**
 * The Footer
 * @returns {React.ReactElement}
 */
function Footer() {
    const { url, languages } = Store.useState();

    const getText      = Store.useText();
    const showSettings = Store.useSettings();


    // Variables
    const hasLanguages = languages.length > 1;


    // Do the Render
    return <Container>
        {hasLanguages && <ConfigIcon
            icon="settings"
            onClick={() => showSettings(true)}
        />}
        <Link href={url} target="_blank">
            {getText("branding")} <b>Conversana Guest</b>
        </Link>
    </Container>;
}

export default Footer;
