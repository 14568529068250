import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Categories           from "Components/Core/Categories";
import Product              from "Components/Core/Product";
import Header               from "Components/Utils/Header";
import Grid                 from "Components/Utils/Grid";
import Html                 from "Components/Utils/Html";



// Styles
const Description = Styled(Html)`
    margin: 0 0 24px calc(32px + 12px + 12px);
`;



/**
 * The Category
 * @returns {React.ReactElement}
 */
function Category() {
    const { currentCategory, products, options } = Store.useState();

    const showPage        = Store.useShowPage();
    const getText         = Store.useText();
    const getCategoryText = Store.useCategoryText();


    // Variables
    const showCategories = Utils.isActive(options.categoriesInProducts);
    const isNone         = currentCategory === -1;
    const name           = isNone ? getText("noCategory") : getCategoryText(currentCategory, "name");
    const description    = isNone ? "" : getCategoryText(currentCategory, "description");


    // Calculates the List
    const list = React.useMemo(() => {
        if (isNone) {
            return products.filter(({ categoryID }) => categoryID === 0);
        }
        return products.filter(({ categoryID }) => categoryID === currentCategory);
    }, [ currentCategory ]);


    // Do the Render
    return <>
        {showCategories && <Categories />}

        <Header
            title={name}
            onBack={() => showPage()}
        />
        <Description addBreaks>{description}</Description>
        <Grid>
            {list.map((item) => <Product
                key={item.id}
                elem={item}
            />)}
        </Grid>
    </>;
}

export default Category;
