import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Button               from "Components/Utils/Button";
import Html                 from "Components/Utils/Html";
import Icon                 from "Components/Utils/Icon";
import Dialog               from "Components/Utils/Dialog";



// Styles
const Container = Styled(Dialog)`
    position: absolute;
    right: calc((100vw - 800px) / 2);
    bottom: calc(24px + 56px);
    width: 520px;
    max-height: calc(100dvh - 24px - 24px - 56px);

    @media (max-width: 800px) {
        right: var(--main-padding);
    }
`;

const Content = Styled.div`
    overflow: auto;
`;

const Product = Styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 8px 0;
    border-bottom: 1px solid rgb(235, 236, 240);

    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        border-bottom: 0;
    }
`;

const Image = Styled.img`
    width: 48px;
    height: 48px;
    object-fit: contain;

    @media (max-width: 500px) {
        display: none;
    }
`;

const Name = Styled.div`
    flex-grow: 2;
`;

const Total = Styled(Html)`
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
`;

const Empty = Styled.div`
    width: 100%;
    text-align: center;
    font-weight: 100;
    font-size: 24px;
    margin-bottom: 8px;
`;

const Buttons = Styled.div`
    display: flex;
    background-color: var(--store-button-bg);
    color: var(--store-button-text);
    border-radius: var(--border-radius-small);

    .icon {
        padding: 6px 10px;
        font-size: 16px;
        cursor: pointer;
    }
    div {
        padding: 6px 0;
    }
`;

const Footer = Styled.footer`
    display: flex;
    gap: 8px;

    .btn {
        flex-grow: 1;
    }
`;



/**
 * The Cart
 * @returns {React.ReactElement}
 */
function Cart() {
    const { backUrl, cartAmounts } = Store.useState();

    const showCart       = Store.useShowCart();
    const setCart        = Store.useSetCart();
    const getText        = Store.useText();
    const getProduct     = Store.useGetProduct();
    const getProductText = Store.useProductText();
    const confirmOrder   = Store.useConfirmOrder();
    const cancelOrder    = Store.useCancelOrder();


    // Lists the Products
    const list = React.useMemo(() => {
        const result = [];
        for (const [ productID, amount ] of Object.entries(cartAmounts)) {
            if (amount > 0) {
                const product = getProduct(productID);
                result.push({
                    id       : productID,
                    name     : getProductText(productID, "name"),
                    hasImage : product.hasImage,
                    imageUrl : product.imageUrl,
                    price    : product.price,
                    total    : product.price * amount,
                    amount,
                });
            }
        }
        return result;
    }, [ JSON.stringify(cartAmounts) ]);


    // Handles the Add
    const handleAdd = ({ id, amount }) => {
        setCart(id, amount + 1);
    };

    // Handles the Remove
    const handleRemove = ({ id, amount }) => {
        setCart(id, amount - 1);
    };

    // Handles the Confirm
    const handleConfirm = async () => {
        handleClose();
        if (await confirmOrder()) {
            window.location.href = backUrl;
        }
    };

    // Handles the Cancel
    const handleCancel = async () => {
        handleClose();
        if (await cancelOrder()) {
            window.location.href = backUrl;
        }
    };

    // Handles the Close
    const handleClose = () => {
        showCart(false);
    };



    // Do the Render
    const hasProducts = list.length > 0;

    return <Container
        title={getText("orderTitle")}
        onClose={handleClose}
    >
        <Content>
            {hasProducts ? list.map((elem) => <Product key={elem.id}>
                {elem.hasImage && <Image
                    src={elem.imageUrl}
                    alt={elem.name}
                />}
                <Name>{elem.name}</Name>
                <Total>{Utils.formatPrice(elem.total)}</Total>
                <Buttons>
                    <Icon icon="minus" onClick={() => handleRemove(elem)} />
                    <div>{elem.amount}</div>
                    <Icon icon="plus" onClick={() => handleAdd(elem)} />
                </Buttons>
            </Product>) : <Empty>{getText("orderEmpty")}</Empty>}
        </Content>

        <Footer>
            <Button
                isHidden={!hasProducts}
                onClick={handleConfirm}
                message={getText("confirmOrder")}
                isPrimary
            />
            <Button
                onClick={handleCancel}
                message={getText("cancelOrder")}
            />
        </Footer>
    </Container>;
}

export default Cart;
