import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Utils/Store";

// Components
import Button               from "Components/Utils/Button";



// Styles
const Container = Styled.header.attrs(({ top }) => ({ top }))`
    position: sticky;
    top: ${(props) => props.top}px;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    padding: 40px 0 12px 12px;
    background-color: white;
`;

const Title = Styled.h2`
    margin: 0;
    font-size: 24px;
`;



/**
 * The Header
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Header(props) {
    const { title, onBack } = props;

    const { headerHeight } = Store.useState();


    // Do the Render
    return <Container top={headerHeight}>
        <Button
            icon="back"
            onClick={onBack}
            isPrimary
        />
        <Title>
            {title}
        </Title>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Header.propTypes = {
    title  : PropTypes.string.isRequired,
    onBack : PropTypes.func.isRequired,
};

export default Header;
