import React                from "react";
import PropTypes            from "prop-types";
import Utils                from "./Utils";
import API                  from "./API";



// Variables
const initialState = {
    error            : false,
    loading          : true,
    headerHeight     : 0,

    currentCategory  : 0,
    currentProduct   : 0,
    currentSearch    : "",
    showCart         : false,
    showSettings     : false,
    language         : "",

    storeHash        : "",
    conversationHash : "",
    backUrl          : "",
    name             : "",

    texts            : {},
    languages        : [],
    options          : {},
    banners          : [],
    categories       : [],
    products         : [],

    cartAmounts      : {},
};
const rootReducer = (state, action) => {
    return { ...state, ...action };
};
const Context = React.createContext([]);



/**
 * Creates the Store Provider
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Provider({ children }) {
    const [ state, dispatch ] = React.useReducer(rootReducer, initialState);
    const store = React.useMemo(() => [ state, dispatch ], [ state ]);

    return <Context.Provider value={store}>
        {children}
    </Context.Provider>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Provider.propTypes = {
    children : PropTypes.any,
};



/**
 * Returns the Store Hook
 * @returns {Array}
 */
function useStore() {
    return React.useContext(Context);
}

/**
 * Returns the State Hook
 * @returns {Object}
 */
function useState() {
    const [ state ] = useStore();
    return state;
}

/**
 * Returns the Dispatch Hook
 * @returns {Function}
 */
function useDispatch() {
    const [ , dispatch ] = useStore();
    return dispatch;
}



/**
 * Returns a function to Set the Header Height
 * @returns {Function}
 */
function useHeaderHeight() {
    const dispatch = useDispatch();
    return (headerHeight) => {
        dispatch({ headerHeight });
    };
}

/**
 * Returns the Show Page Hook
 * @returns {Function}
 */
function useShowPage() {
    const dispatch = useDispatch();

    return (currentCategory = 0, currentSearch = "") => {
        dispatch({ currentCategory, currentSearch });
    };
}

/**
 * Returns the Show Product Hook
 * @returns {Function}
 */
function useShowProduct() {
    const dispatch = useDispatch();

    return (currentProduct = 0) => {
        dispatch({ currentProduct });
    };
}

/**
 * Returns the Add Cart Hook
 * @returns {Function}
 */
function useShowCart() {
    const dispatch = useDispatch();

    return (showCart = false) => {
        dispatch({ showCart });
    };
}

/**
 * Returns the Add Cart Hook
 * @param {Number} productID
 * @returns {Array}
 */
function useAddCart(productID) {
    const { cartAmounts } = useState();
    const dispatch = useDispatch();

    const update = (amount) => {
        cartAmounts[productID] = Math.max((cartAmounts[productID] || 0) + amount, 0);
        dispatch({ cartAmounts });
    };
    return [ cartAmounts[productID] || 0, update ];
}

/**
 * Returns the Set Cart Hook
 * @returns {Function}
 */
function useSetCart() {
    const { cartAmounts } = useState();
    const dispatch = useDispatch();

    return (productID, amount) => {
        cartAmounts[productID] = Math.max(amount, 0);
        dispatch({ cartAmounts });
    };
}



/**
 * Returns a function to Show the Settings
 * @returns {Function}
 */
function useSettings() {
    const dispatch = useDispatch();
    return (showSettings) => {
        dispatch({ showSettings });
    };
}

/**
 * Returns the Language Hook
 * @returns {Object}
 */
function useLanguage() {
    const { conversationHash } = useState();
    const dispatch = useDispatch();

    return (language) => {
        if (conversationHash) {
            Utils.storeItem("conversana-language", language);
        }
        dispatch({ language });
    };
}

/**
 * Returns the Text Hook
 * @returns {Object}
 */
function useText() {
    const { language, texts, options } = useState();

    return (property, defaultValue = "") => {
        if (options[property] && Utils.isObject(options[property])) {
            return options[property][language] || defaultValue;
        }
        if (texts[property]) {
            return texts[property][language] || defaultValue;
        }
        return defaultValue;
    };
}



/**
 * Returns the Get Category Hook
 * @returns {Function}
 */
function useGetCategory() {
    const { categories } = useState();

    return (categoryID) => {
        return categories.find(({ id }) => id === Number(categoryID)) || {};
    };
}

/**
 * Returns the Category Text Hook
 * @returns {Object}
 */
function useCategoryText() {
    const { language } = useState();
    const getCategory  = useGetCategory();

    return (categoryID, property) => {
        const texts = getCategory(categoryID).texts || {};
        return getItemText(texts, language, property);
    };
}

/**
 * Returns the Get Product Hook
 * @returns {Function}
 */
function useGetProduct() {
    const { products } = useState();

    return (productID) => {
        return products.find(({ id }) => id === Number(productID)) || {};
    };
}

/**
 * Returns the Product Text Hook
 * @returns {Object}
 */
function useProductText() {
    const { language } = useState();
    const getProduct   = useGetProduct();

    return (productID, property) => {
        const texts = getProduct(productID).texts || {};
        return getItemText(texts, language, property);
    };
}

/**
 * Returns the Text for the given Property and Language
 * @param {Object}  texts
 * @param {String}  language
 * @param {String}  property
 * @param {String=} defaultValue
 * @returns {String}
 */
function getItemText(texts, language, property, defaultValue = "") {
    if (texts[language]?.[property]) {
        return texts[language][property];
    }
    for (const langTexts of Object.values(texts)) {
        if (langTexts[property]) {
            return langTexts[property];
        }
    }
    return defaultValue;
}



/**
 * Returns a function to Initialize the Store
 * @returns {Function}
 */
function useInitial() {
    const dispatch = useDispatch();

    return async () => {
        // Obtain the store hash
        const hash             = window.location.pathname.substring(1);
        const storeHash        = hash.startsWith("preview") ? hash.substring(8) : "";
        const conversationHash = !hash.startsWith("preview") ? hash : "";

        if (!storeHash && !conversationHash) {
            dispatch({
                error   : true,
                loading : true,
            });
            return;
        }

        // Obtain the initial data
        let result = { error : true };
        if (storeHash) {
            result = await API.getPreview({ storeHash });
        } else {
            result = await API.getInitial({ conversationHash });
        }
        if (result.error) {
            dispatch({
                error   : true,
                loading : true,
            });
            return;
        }

        // Store the data
        dispatch({
            error            : false,
            loading          : false,
            language         : Utils.getLanguage(result.languages, result.defaultLanguage),

            storeHash        : storeHash,
            conversationHash : conversationHash,
            backUrl          : result.backUrl,
            name             : result.name,
            texts            : result.texts,
            languages        : result.languages,
            options          : result.options,
            banners          : result.banners,
            categories       : result.categories,
            products         : result.products,
            cartAmounts      : result.cartAmounts || {},
        });
    };
}

/**
 * Returns a function to Preview the Store
 * @returns {Function}
 */
function usePreview() {
    const { storeHash } = useState();
    const dispatch = useDispatch();

    return async () => {
        const result = await API.getPreview({ storeHash });
        if (result.error) {
            dispatch({
                error   : true,
                loading : true,
            });
            return;
        }

        // Store the data
        dispatch({
            options : result.options,
        });
    };
}

/**
 * Returns a function to Confirm the Order
 * @returns {Function}
 */
function useConfirmOrder() {
    const { conversationHash, cartAmounts } = useState();

    return async () => {
        if (!conversationHash) {
            return false;
        }

        const result = await API.confirmOrder({
            conversationHash,
            items : JSON.stringify(cartAmounts),
        });
        return !result.error;
    };
}

/**
 * Returns a function to Cancel the Order
 * @returns {Function}
 */
function useCancelOrder() {
    const { conversationHash } = useState();

    return async () => {
        if (!conversationHash) {
            return false;
        }

        const result = await API.cancelOrder({
            conversationHash,
        });
        return !result.error;
    };
}




// The public API
export default {
    Provider,
    useState,

    useHeaderHeight,
    useShowPage,
    useShowProduct,
    useShowCart,
    useAddCart,
    useSetCart,

    useSettings,
    useLanguage,
    useText,

    useGetCategory,
    useCategoryText,
    useGetProduct,
    useProductText,

    useInitial,
    usePreview,
    useConfirmOrder,
    useCancelOrder,
};
