import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Categories           from "Components/Core/Categories";
import Product              from "Components/Core/Product";
import Header               from "Components/Utils/Header";
import Grid                 from "Components/Utils/Grid";



// Styles
const Empty = Styled.div`
    width: 100%;
    text-align: center;
    font-weight: 100;
    font-size: 32px;
`;



/**
 * The Search
 * @returns {React.ReactElement}
 */
function Search() {
    const { currentCategory, currentSearch, products, options } = Store.useState();

    const showPage        = Store.useShowPage();
    const getText         = Store.useText();
    const getCategoryText = Store.useCategoryText();
    const getProductText  = Store.useProductText();


    // Variables
    const showCategories = Utils.isActive(options.categoriesInProducts);


    // Gets the Title
    const title = React.useMemo(() => {
        let result = getText("searchResultsTitle");
        if (currentCategory) {
            const categoryName = getCategoryText(currentCategory, "name");
            result += ` - ${categoryName}`;
        }
        return result;
    }, [ currentCategory ]);


    // Searches the Products
    const list = React.useMemo(() => {
        const result = [];
        const search = currentSearch.toLowerCase();
        for (const product of products) {
            if (currentCategory && currentCategory !== product.categoryID) {
                continue;
            }
            const name        = getProductText(product.id, "name").toLowerCase();
            const description = getProductText(product.id, "description").toLowerCase();
            if (name.includes(search) || description.includes(search)) {
                result.push(product);
            }
        }
        return result;
    }, [ currentSearch ]);


    // Do the Render
    return <>
        {showCategories && <Categories />}

        <Header
            title={title}
            onBack={() => showPage(currentCategory)}
        />
        <Grid>
            {list.length > 0 ? list.map((item) => <Product
                key={item.id}
                elem={item}
            />) : <Empty>{getText("searchNoneResults")}</Empty>}
        </Grid>
    </>;
}

export default Search;
