import React                from "react";
import Store                from "Utils/Store";

// Core
import App                  from "Core/App";



/**
 * The Main
 * @returns {React.ReactElement}
 */
function Main() {
    const { error, loading, storeHash } = Store.useState();

    const initialize  = Store.useInitial();
    const update      = Store.usePreview();
    const setLanguage = Store.useLanguage();


    // Loads the Store
    React.useEffect(() => {
        initialize();
    }, []);

    // Loads the Preview
    React.useEffect(() => {
        if (storeHash) {
            window.addEventListener("message", handleMessage);
        }
    }, [ storeHash ]);

    // Handles the message
    const handleMessage = (event) => {
        if (storeHash && typeof event.data === "string") {
            if (event.data === "update") {
                update();
            }
            if (event.data.startsWith("tongue")) {
                setLanguage(event.data.replace("tongue-", ""));
            }
        }
    };


    // Do the Render
    if (error || loading) {
        return <React.Fragment />;
    }
    return <App />;
}

export default Main;
