import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";



// Styles
const Container = Styled.section.attrs(({ columns, twoRows }) => ({ columns, twoRows }))`
    --category-size: 100px;

    gap: 16px;
    padding: 4px;
    white-space: nowrap;
    overflow-x: auto;

    ${(props) => props.twoRows ? `
        max-width: ${(props) => `calc((var(--category-size) + 16px) * ${props.columns} - 16px)`};
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: repeat(${props.columns}, 1fr);
        margin: 0 auto;
    ` : `
        display: flex;
        flex-wrap: nowrap;

        div:first-child {
            margin-left: auto;
        }
        div:last-child {
            margin-right: auto;
        }
    `}
`;

const Content = Styled.div.attrs(({ hasImage, asSquares }) => ({ hasImage, asSquares }))`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 8px;
    padding: ${(props) => props.hasImage ? "4px 16px 4px 8px" : "4px 16px"};
    border-radius: var(--border-radius);
    background-color: white;
    box-shadow: var(--box-shadow);
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;

    ${(props) => props.asSquares && `
        flex-direction: column;
        width: var(--category-size);
        min-width: var(--category-size);
        min-height: var(--category-size);
        padding: 8px;
        white-space: wrap;
    `}
`;

const Image = Styled.img`
    width: 48px;
    height: 48px;
    object-fit: cover;
`;



/**
 * The Categories
 * @returns {React.ReactElement}
 */
function Categories() {
    const { categories, options } = Store.useState();

    const showPage        = Store.useShowPage();
    const getCategoryText = Store.useCategoryText();


    // The Current State
    const [ width, setWidth ] = React.useState(window.innerWidth);

    // Handle the Resize
    React.useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);


    // Variables
    const asSquares = Utils.isActive(options.categoriesAsSquares);
    const twoRows   = Utils.isActive(options.categoriesTwoRows);

    // Calculate the columns
    const columns = React.useMemo(() => {
        const catWidth     = (asSquares ? 100 : 160) + 16;
        const maxColumns   = Math.ceil(categories.length / 2);
        const innerWidth   = Math.min(width, 800) - 48;
        const totalColumns = Math.floor(innerWidth / catWidth);
        const columns      = Math.max(totalColumns, maxColumns);

        if (columns > columns / 2 && categories.length - columns <= 2) {
            return categories.length;
        }
        return columns;
    }, [ categories.length, width ]);

    const showInTwoRows = Boolean(twoRows && columns > categories.length);


    // Do the Render
    if (!categories.length) {
        return <React.Fragment />;
    }
    return <Container
        columns={columns}
        twoRows={showInTwoRows}
    >
        {categories.map((elem) => <Content
            key={elem.id}
            hasImage={elem.hasImage}
            asSquares={asSquares}
            onClick={() => showPage(elem.id)}
        >
            {elem.hasImage && <Image
                src={elem.imageUrl}
                alt={getCategoryText(elem.id, "name")}
            />}
            {getCategoryText(elem.id, "name")}
        </Content>)}
    </Container>;
}

export default Categories;
